import { useEffect, useMemo, useState } from "react";
import { Grid } from "@material-ui/core";
import axios from "axios";
import DashboardUsers from "../components/dashboard-home/dashboard-users/DashboardUsers";
import { CompaniesService } from "../core/services/companies.service";
import useGlobalState from "../store/globalState";
import useDashboardUsersState from "../store/dashboardUsersState";
import useDashboardState from "../store/dashboardState";
import { AlertOld } from "../components/general/AlertOld";
import DashboardUsersClient from "../components/dashboard-home/generadores/DashboardUsersProvider";
import { Provider } from "../types/companies-service";

const Providers = () => {
  const { handleOpenUserDialog, providers, setProviders, setIsLoadingCollaborator, isLoadingCollaborator } = useDashboardUsersState();
  const { infoAlert, handleCloseInfoAlert } = useDashboardState();

  const { mainCompany, setAlertInfo, typeService } = useGlobalState();
  const [filteredProviders, setFilteredProviders] = useState<Provider[] | null>(null);

  const renderProviders = useMemo(() => (filteredProviders ? filteredProviders : providers), [filteredProviders, providers]);

  useEffect(() => {
    if (mainCompany?.id) {
      findAllProviders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCompany?.id]);

  const findAllProviders = async () => {
    setIsLoadingCollaborator(true);
    if (!mainCompany?.id) return;
    try {
      const data = await CompaniesService.getInstance().getAllCompanyProviders(mainCompany?.id);
      console.log(data)
      let providersWithGeneradores
      if (typeService === "generators") {
        providersWithGeneradores = data.respuesta.filter(provider => provider?.generadores === true);
      } else {
        providersWithGeneradores = data?.respuesta?.filter(provider => provider?.generadores === false) ?? [];
      }

       
      //@ts-ignore
      setProviders([...providersWithGeneradores]);
    } finally {
      setIsLoadingCollaborator(false);
    }
  };

  const handleSearch = (value: string) => {
    if (value) {
      const parseValue = value.toLowerCase();
      setFilteredProviders(
        providers.filter((provider) => {
          return (
            provider?.name?.toLowerCase().includes(parseValue) ||
            provider?.rfc?.toLowerCase().includes(parseValue)
          );
        })
      );
    } else {
      setFilteredProviders(null);
    }
  };

  const handleAnswerInvitation = async (processId: string, answer: boolean) => {
    setIsLoadingCollaborator(true);
    try {
      if (!processId) throw new Error("Hay un problema con los datos de la invitación");
      await CompaniesService.getInstance().asnwerCollaboration(processId, answer);
      await findAllProviders();
    } catch (error) {
      let message = "Hubo un problema al responder la invitación";
      if (axios.isAxiosError(error)) {
        message = error.message === "Network Error" ? "Error al conectar con el servidor" : error.message;
      }
      setAlertInfo({
        message,
        type: "error",
      });
    } finally {
      setIsLoadingCollaborator(false);
    }
  };

  return (
    <Grid container>
      {typeService === 'generators' ?
        <DashboardUsersClient
          title="Proveedores"
          userType="proveedor"
          hasServices
          filters={userTypeFilters}
          onAdd={() => handleOpenUserDialog({ formType: "provider", isNewUser: false })}
          onAnswerInvitation={handleAnswerInvitation}
          onSearch={handleSearch}
          users={renderProviders}
          loading={isLoadingCollaborator}
          loadProvider={findAllProviders}
        /> :
        <DashboardUsers
          title="Proveedores"
          userType="proveedor"
          hasServices
          filters={userTypeFilters}
          onAdd={() => handleOpenUserDialog({ formType: "provider", isNewUser: false })}
          onAnswerInvitation={handleAnswerInvitation}
          onSearch={handleSearch}
          users={renderProviders}
          loading={isLoadingCollaborator}
          loadProvider={findAllProviders}
        />
      }

      <AlertOld open={infoAlert.show} title={infoAlert.title} type={infoAlert.type} onConfirm={handleCloseInfoAlert} />
    </Grid>
  );
};

const userTypeFilters: UserFilter[] = [
  {
    label: "Personas Físicas",
    type: "fisica",
  },
  {
    label: "Personas Morales",
    type: "moral",
  },
];

export default Providers;