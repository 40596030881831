import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import useCustomToast from "../../../core/hooks/useCustomToast";
import { ServiceStage } from "../../../core/models/service.models";
import useStagesState from "../../../store/stagesState";
import MatFisButton from "./MatFisButton";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DownLoadDocumentProps } from "../../../core/models/stage.models";
import { Alert } from "@material-ui/lab";
import esLocale from "date-fns/locale/es"; // 🔹 Importa la localización en español
import { dateFormat } from "../utils";


const DownloadDocsDialog = ({
  onClose,
  stage,
  companyId,
  serviceId,
}: {
  onClose: any;
  stage: ServiceStage;
  companyId: string;
  serviceId: string;
}) => {
  const classes = useStyles();
  const toast = useCustomToast();
  const [all, setAll] = useState(false);
  const [dateRange, setDateRange] = useState(false)
  const [selectedReqIds, setSelectedReqIds] = useState<string[]>([]);
  const [initialDate, setInitialDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [initialDateError, setInitialDateError] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const downloadDocuments = useStagesState((s) => s.downloadServiceStageRequirementDocument);

  const { currentService } = useStagesState();

  const handleChange = (event) => {
    const reqId = event.target.value;
    setSelectedReqIds((prevState) => {
      if (event.target.checked) {
        return [...new Set([...prevState, reqId])];
      } else {
        return prevState.filter((id) => id !== reqId);
      }
    });
    setShowErrorAlert(false)
    setAll(false);
  };

  const handleChangeAll = (event) => {
    const checked = event.target.checked;
    if (checked) {
      setShowErrorAlert(false)
      setSelectedReqIds(stage?.groups?.flatMap((group) => group?.requirements?.map((req) => req.uid)));
    } else {
      setSelectedReqIds([]);
    }
    setAll(event.target.checked);
  };

  const handleDownload = async () => {
    const body: DownLoadDocumentProps = {
      initialDate: initialDate.toISOString(),
      endDate: dateRange ? endDate.toISOString() : null,
      requirementNameList: selectedReqIds
    }
    if (selectedReqIds.length === 0) {
      setShowErrorAlert(true)
    } else if (!initialDate) setInitialDateError(true)
    else {
      setShowErrorAlert(false)
      const downloaded = await downloadDocuments(companyId, stage.name, serviceId, body);
      if (!downloaded) {
        toast.error("No se pudo descargar los documentos");
      } else {
        onClose();
      }
    }
  };

  useEffect(() => {
    console.log(currentService, 'currentService')
  }, [currentService])

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <DialogTitle
        disableTypography
      >
        <Box>
          <Box style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
            <span className={classes.dialogTitleText}>Descargar documentos</span>
            <IconButton edge="end" onClick={onClose}>
              <CloseIcon style={{ color: "#7A92C5" }} />
            </IconButton>
          </Box>
          <Box style={{
            textAlign: 'right'
          }}>
            <Typography style={{ fontWeight: 'normal' }} >Inicio del servicio: {dateFormat(new Date(currentService.serviceInitDate))}</Typography>
            <Typography style={{ fontWeight: 'normal' }} >Fin del servicio: {dateFormat(new Date(currentService.serviceEndDate))}</Typography>
          </Box>
          <Box>
            <FormControlLabel
              value={""}
              control={<Checkbox checked={all} />}
              label={<Typography style={{ fontWeight: 'bold' }} >TODOS</Typography>}
              onChange={handleChangeAll}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {stage?.groups?.map((group, idx) => (
          <div key={idx}>
            <h4
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                marginTop: "1rem",
              }}
            >
              {group.name}
            </h4>
            <FormControl component="fieldset">
              {group.requirements?.map((req) => {
                return (
                  <FormControlLabel
                    key={req.uid}
                    value={req.uid}
                    control={<Checkbox checked={selectedReqIds?.includes(req.uid)} />}
                    label={req.name}
                    onChange={handleChange}
                  />
                );
              })}
            </FormControl>
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Box width='100%'>
          <Box>
            <FormControlLabel name="dateRange" value={""} control={<Switch checked={dateRange} />} label={"Consultar por rango de periodos"} onChange={() => setDateRange((prev) => !prev)} />
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <Box display='flex' justifyContent="space-between">
                <KeyboardDatePicker
                  views={["year", "month"]} // Selecciona solo mes y año
                  label={`${dateRange ? 'Periodo inicio' : 'Periodo'} `}
                  value={initialDate}
                  onChange={(date) => setInitialDate(date)}
                  format="MM/yyyy" // Formato mes/año
                  error={initialDateError} // Muestra error si es true
                  helperText={initialDateError ? "Este campo es obligatorio" : ""}
                  style={{
                    width: '49%'
                  }}
                  minDate={new Date("2017")}
                  maxDate={endDate}
                />
                {
                  dateRange && <KeyboardDatePicker
                    views={["year", "month"]} // Selecciona solo mes y año
                    label='Periodo fin'
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    format="MM/yyyy" // Formato mes/año
                    style={{
                      width: '49%'
                    }}
                    helperText={endDate && initialDate && endDate < initialDate ? "La fecha de fin no puede ser menor que la de inicio" : ""}
                    minDate={initialDate}
                    maxDate={new Date(currentService.serviceEndDate)}
                  />
                }
              </Box>
            </MuiPickersUtilsProvider>
            {showErrorAlert && (
              <Alert severity="error" style={{ marginTop: "1rem" }}>
                Seleccione al menos un tipo de documento
              </Alert>
            )}
          </Box>
          <Grid container justifyContent="center" style={{ padding: "2rem 0 1rem 0" }}>
            <MatFisButton variant="contained" color="primary" type="button" onClick={handleDownload}>
              Descargar
            </MatFisButton>
          </Grid>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogContentRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 20,
  },
  dialogTitleText: {
    fontWeight: 600,
    fontSize: "1.125rem",
    lineHeight: "21.33px",
  },
  dialogContentText: {
    fontSize: "0.875rem",
    lineHeight: "17px",
    color: "#7A92C5",
    marginBottom: 40,
  },
});

export default DownloadDocsDialog;
