import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Grid, List, ListItem, Typography, makeStyles, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { ServiceStage } from '../../../core/models/service.models';
/* import { FaCircle } from "react-icons/fa"; */
/* import { FaCircle as FaCircleAlt } from "react-icons/fa"; */
import { ServicesService } from '../../../core/services/services.service';
import useCustomToast from '../../../core/hooks/useCustomToast';

type StageSuaDialogProps = {
  onClose: () => void;
  serviceId: string;
  selectedStage: ServiceStage;
};

const months = [
  { id: 1, mes: 'Enero' },
  { id: 2, mes: 'Febrero' },
  { id: 3, mes: 'Marzo' },
  { id: 4, mes: 'Abril' },
  { id: 5, mes: 'Mayo' },
  { id: 6, mes: 'Junio' },
  { id: 7, mes: 'Julio' },
  { id: 8, mes: 'Agosto' },
  { id: 9, mes: 'Septiembre' },
  { id: 10, mes: 'Octubre' },
  { id: 11, mes: 'Noviembre' },
  { id: 12, mes: 'Diciembre' }
];

const StageSuaDialog: React.FC<StageSuaDialogProps> = ({ onClose, serviceId, selectedStage }) => {
  const classes = useStyles();
  const [month, setMonth] = useState(0);
  const [years, setYears] = useState([]);
  const [year, setYear] = useState<any>();
  const [data, setData] = useState<any>([]);
  const [tableRows, setTableRows] = useState<any[]>();
  const [loading, setLoading] = useState(true);
  const toast = useCustomToast();

  useEffect(() => {
    let isMounted = true; // Variable de control

    const getData = async () => {
      try {
        const data = await ServicesService.getInstance().getSuaNominaCfdi(serviceId);
        if (isMounted) {
          const uniqueYears = [...new Set(data.respuesta.periodList.map(item => item.year))];
          setYears(uniqueYears);
          setYear(uniqueYears[uniqueYears.length - 1]);
          setData(data.respuesta);
          setLoading(false);
        }
      } catch (error) {
        toast.error("Ha ocurrido un error, intente de nuevo más tarde.");
      }
    };

    getData();

    return () => {
      isMounted = false; // Indica que el componente ha sido desmontado
    };
  }, []);

  useEffect(() => {
    if (data?.periodList) {
      const filteredItems = data.periodList.filter(item => item.year === year && item.month === month);
      const newTableRows = filteredItems.length > 0 ? filteredItems[0].assortDataRowList : [];
      setTableRows(newTableRows);
    }
  }, [month, year]);


  const TrueOrFalseIcon = (aux: boolean) => {
    return aux ? <CheckIcon style={{ color: '#00B341' }} /> : <CloseIcon style={{ color: '#F45F5F' }} />;
  };

  const statusColor = (status: string) => {
    switch (status) {
      case 'FORMATOS_INCORRECTO':
        return '#B1B0B0';
      case 'FALTANTE':
        return '#FE63D1';
      case 'ACEPTADO':
        return '#95D97D';
      case 'RECHAZADO':
        return '#F45F5F';
      case 'PARCIAL':
        return '#F4CD69';
    };
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={onClose}
        maxWidth={false}
        fullWidth={true}
        PaperProps={{
          style: {
            height: '100%',
            width: '100%',
            overflow: 'hidden',
          },
        }}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {!loading ? (
          <DialogContent style={{ padding: '16px' }}>
            <Grid container spacing={2} style={{ height: '100%' }}>
              <Grid item xs={1} style={{ flex: '20%', height: '30%', overflowY: 'auto' }}>
                <Typography variant="h6">Periodos</Typography>
                <List>
                  {years.map((item) => (
                    <ListItem
                      onClick={() => { setYear(item); }}
                      className={item === year ? classes.periodSelectedCard : classes.periodCards}>
                      <span>{item}</span>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item xs={2} style={{ flex: '20%' }}>
                <Typography variant="h6" style={{ visibility: 'hidden' }}>Meses</Typography>
                <List>
                  {data.periodList && data.periodList.map((item) => {
                    if (item.year === year) {
                      return (
                        <ListItem
                          onClick={() => { setMonth(item.month); }}
                          className={item.month === month ? classes.monthSelectedCard : classes.monthCards}
                          style={{ borderLeft: `8px solid ${statusColor(item.status)}`, justifyContent: 'space-between', cursor: 'pointer' }}>
                          <span>{months[item.month - 1].mes /* solo mostramos aquellos que vienen en el arreglo */}</span>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {item.hasDataPayRoll && <FiberManualRecordIcon style={{ color: '#7678ED' }} className={classes.circle} />}
                            {item.hasDataSua && <FiberManualRecordIcon style={{ color: '#52C6BA' }} className={classes.circle} />}
                            {item.hasDataCfdi && <FiberManualRecordIcon style={{ color: '#0092F6' }} className={classes.circle} />}
                          </div>
                        </ListItem>
                      );
                    }
                  })}
                </List>
              </Grid>
              <Grid item xs={9} style={{ flex: '60%', height: '100%' }}>
                <Typography variant="h6" align='center'>RESUMEN TRABAJADORES PUESTOS A DISPOSICIÓN</Typography>
                <div style={{ height: '100%', overflowY: 'auto' }}>
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={4} align="center">LISTA DE NÓMINA</TableCell>
                        <TableCell colSpan={2} align="center">SUA</TableCell>
                        <TableCell colSpan={3} align="center">CFDI DE NÓMINA</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.listHeader} align="center">Nombre Completo</TableCell>
                        <TableCell className={classes.listHeader} align="center">CURP</TableCell>
                        <TableCell className={classes.listHeader} align="center">RFC</TableCell>
                        <TableCell className={classes.listHeader} align="center">NSS</TableCell>
                        <TableCell className={classes.suaHeader} align="center">CURP</TableCell>
                        <TableCell className={classes.suaHeader} align="center">NSS</TableCell>
                        <TableCell className={classes.cfdiHeader} align="center">CURP</TableCell>
                        <TableCell className={classes.cfdiHeader} align="center">RFC</TableCell>
                        <TableCell className={classes.cfdiHeader} align="center">NSS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableRows && tableRows.length > 0 ? (
                        tableRows.map((item, index) => (
                          <TableRow
                            key={index}
                            style={{ backgroundColor: index % 2 === 0 ? '#DCDCDC' : '#F8F8FF' }}
                          >
                            <TableCell align="center" className={classes.tableBodyCell}>{item?.name}</TableCell>
                            <TableCell align="center" className={classes.tableBodyCell}>{item?.curpPayroll?.value}</TableCell>
                            <TableCell align="center" className={classes.tableBodyCell}>{item?.rfcPayroll?.value}</TableCell>
                            <TableCell align="center" className={classes.tableBodyCell}>{item?.nssPayroll?.value}</TableCell>
                            <TableCell align="center" className={classes.tableBodyCell}>{TrueOrFalseIcon(item.curpSua?.value ? true : false)}</TableCell>
                            <TableCell align="center" className={classes.tableBodyCell}>{TrueOrFalseIcon(item.nssSua?.value ? true : false)}</TableCell>
                            <TableCell align="center" className={classes.tableBodyCell}>{TrueOrFalseIcon(item.curpCfdi?.value ? true : false)}</TableCell>
                            <TableCell align="center" className={classes.tableBodyCell}>{TrueOrFalseIcon(item.rfcCfdi?.value ? true : false)}</TableCell>
                            <TableCell align="center" className={classes.tableBodyCell}>{TrueOrFalseIcon(item.nssCfdi?.value ? true : false)}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={9} align="center" >
                            No hay información disponible
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        ) :
          (
            <div style={{ width: "220px", height: "100px", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", gap: "2rem", borderRadius: "15px" }}>
              <CircularProgress color="secondary" />
            </div>
          )}
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  listHeader: {
    backgroundColor: '#7678ED',
    color: 'white',
  },
  suaHeader: {
    backgroundColor: '#52C6BA',
    color: 'white',
  },
  cfdiHeader: {
    backgroundColor: '#0092F6',
    color: 'white',
  },
  monthCards: {
    backgroundColor: '#E7E7E7',
    borderRadius: '8px',
    marginBottom: '5%',
    color: '#03264C',
  },
  monthSelectedCard: {
    backgroundColor: '#2347AD',
    borderRadius: '8px',
    marginBottom: '5%',
    color: 'white',
  },
  periodCards: {
    backgroundColor: '#E7E7E7',
    borderRadius: '8px',
    marginBottom: '5%',
    cursor: 'pointer'
  },
  periodSelectedCard: {
    backgroundColor: '#2347AD',
    borderRadius: '8px',
    marginBottom: '5%',
    color: 'white',
    cursor: 'pointer'
  },
  circle: {
    marginLeft: '8px',
    marginRight: '8px'
  },
  tableBodyCell: {
    padding: '8px',
    wordWrap: 'break-word',
  },
}));

export default StageSuaDialog;
