/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  makeStyles,
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Box,
  Modal,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  Typography,
  } from "@material-ui/core";
import * as Yup from "yup";
import TextField from "../../inputs/inputs-v2/RenderTextFieldV2";
import { useFormik } from "formik";
import { GeneralNextFormInfoReqBody } from "../../../types/companies-service";
import TableJson from "../../dashboard-home/generadores/tabs-buttons/catalogs/TableJsonCatalogue";
import useCatalogoState from '../../../store/generadores/catalogoState';
import useGeneralFormCompleteState from "../../../store/generadores/generalFormState";
import { GeneradoresServices } from "../../../core/services/generadores.service";
import useCustomToast from "../../../core/hooks/useCustomToast";
import useGeneratorClientState from "../../../store/generadores/generadorClientState";
import useUiState from "../../../store/uiState";
import useGlobalState from "../../../store/globalState";


interface RenderNextProps {
  goToPreviousForm?: any;
};

interface Sheet {
  name: string;
  rowAxList: {
    cellAxList: string[];
    cssList?: string;
  }[];
}

const FormGeneralNext = ({goToPreviousForm}: RenderNextProps) => {
  const classes = useStyles();
  const toast = useCustomToast();
  const { mainCompany } = useGlobalState();
  const idProvider = mainCompany?.id;
  const idCompany = mainCompany?.clients !== null ? mainCompany?.clients[0]?.clientId : '';
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [regionSelected, setRegionSelected] = useState<any>('');
  const idForm = useGeneralFormCompleteState((state) => state.idForm);
  const generalFormBody = useGeneralFormCompleteState((state) => state.generalFormBody);
  const generalNextBody = useGeneralFormCompleteState((state) => state.generalNextBody);
  const updateGeneralBody = useGeneralFormCompleteState((state) => state.updateGeneralForm);
  const updateGeneralNextBody = useGeneralFormCompleteState((state) => state.updateGeneralNextForm);
  const catalogo = useCatalogoState((state) => state.summaryData);
  const [summaryData, setSummaryData] = useState<any>({catalogo: 0, materiales: 0, servicios: 0 , movimientos: 0});
  const { getCatalogueJson, getCatalogues, catalogues} = useGeneratorClientState();
  const [json, setJson] = useState<Sheet[]>([]);
  const [regionsTree, setRegionsTree] = useState([]);
  const [regions, setRegions] = useState([]);
  const { setShowGenericLoader } = useUiState();

  useEffect(() => {
  
    // Verifica si servicesList está definida y tiene al menos un elemento antes de acceder a las propiedades internas.
    let materiales = generalFormBody?.servicesList?.[0]?.materialsList?.[0]?.amount ?? 0;
    let servicios = generalFormBody?.servicesList?.[0]?.servicesList?.[0]?.amount ?? 0;
    let movimientos = generalFormBody?.servicesList?.[0]?.movementsList?.[0]?.amount ?? 0;
  
    // Actualiza el estado con los valores calculados.
    setSummaryData({ materiales, servicios, movimientos });
  
    // Llamadas a funciones para obtener catálogos y árboles de regiones.
    getCatalogues(idCompany);
    getRegionsTree();
  }, []);
  
  const getRegionsTree = async () => {
    await GeneradoresServices.getInstance().getRegionManagerByCompany(idCompany).then((data) => {//chequear si dejar este Id o no
      setRegionsTree(data.respuesta.managerInfoList);
      const regionsFiltered = data.respuesta.managerInfoList.reduce((acc, item) => {
        if (acc.indexOf(item.region) === -1) {
          acc.push(item.region);
        }
        return acc;
      }, []);
      setRegions(regionsFiltered);
    });
  };

  const handleOpenAlert = async () => {
    setShowGenericLoader(true);
    try {
      let auxStatus = false;
      catalogues.map(async (sheet) => {
        auxStatus = true;
        if (sheet.status === true) {
          const jsonFile = await getCatalogueJson(sheet.idFile);
          setJson(jsonFile.workbookAx.sheetAxList);
          setShowGenericLoader(false);
        }
      });
      if (!auxStatus) {
        toast.warnign("No hay ningún catálogo disponible para consultar, contacte a su administrador.");
      } else {
        setOpenAlert(true);
      }
    } catch (error) {
      setShowGenericLoader(false);
      toast.error("Algo ha salido mal, por favor guarde sus cambios y recargue la página.");
    }

  };

  const formatAndConvert = (total) => {
    // Verifica si el total es una cadena
    if (typeof total === 'string') {
      // Elimina los caracteres no numéricos (signos de dólar y comas)
      total = total.replace(/[$,]/g, '');
    }
    // Convierte a número flotante
    return parseFloat(total);
  };
  
  const handleCloseAlert = () => {
    toast.warnign("Las selecciones se limpiarán al salir del catálogo.");
  
    if (catalogo !== null) {
      let materiales = 0;
      let servicios = 0;
      let movimientos = 0;
  
      catalogo?.details.forEach((sheet) => {
        let total = formatAndConvert(sheet.total);
  
        if (isNaN(total)) {
          console.warn(`Invalid total for sheet: ${sheet.sheetName}`, sheet.total);
          total = 0;
        }
  
        if (sheet.sheetName === "Catalogo PE Materiales" || sheet.sheetName === "Banamex Materiales") {
          materiales += total;
        } else if (sheet.sheetName === "Movimientos") {
          movimientos = total;  // Asegúrate de usar la conversión
        } else {
          servicios += total;
        }
      });
  
      // Actualizamos el resumen
      setSummaryData({ catalogo, materiales, servicios, movimientos });
    }
  
    console.log("suma", summaryData);
    setOpenAlert(false);
  };
  
  const formatCurrency = (value, locale = 'es-MX', currency = 'MXN') => {
    if (isNaN(value) || value === null) {
      return `${currency} 0.00`; // Devuelve un valor predeterminado si el valor no es un número
    }
    
    return parseFloat(value).toLocaleString(locale, { style: 'currency', currency });
  };

  const totalSum = (summaryData?.servicios || 0) + (summaryData?.materiales || 0) + (summaryData?.movimientos || 0);

// Formatea el resultado como moneda
  const formattedTotalSum = formatCurrency(totalSum);


  const handleSubmit = async (values: GeneralNextFormInfoReqBody) => {
    setShowGenericLoader(true);
  // const total = summaryData.materiales + summaryData.servicios + summaryData.movimientos;
  const total = totalSum ;
    const body: any = {//general form object complete 
      clientName: generalFormBody.clientName,
      csp: generalFormBody.csp,
      idPlace: generalFormBody.idPlace,
      cot: generalFormBody.cot,
      cuenta: generalFormBody.cuenta,
      serviceOfferedIdList: generalFormBody.serviceOfferedIdList,
      startDate: generalFormBody.startDate,
      endDate: generalFormBody.endDate,
      idCode: generalFormBody.idCode,
      name: generalFormBody.name,
      idSupplier: generalFormBody.idSupplier,
      idSap: generalFormBody.idSap,
      district: generalFormBody.district,
      cluster: generalFormBody.cluster,
      city: generalFormBody.city,
      state: generalFormBody.state,
      address: generalFormBody.address,
      projectName: generalFormBody.projectName,
      area: generalFormBody.area,
      assignment: generalFormBody.assignment,
      businessUnit: generalFormBody.businessUnit,
      supervisor: {
        name: values.name,
        lastName: values.lastName,
        motherLastName: values.motherLastName,
        phone: values.phone,
        email: values.email,
        listRegions: values.listRegions,
        listResponsible: values.listResponsible,
        phoneResponsible: values.phoneResponsible,
        emailResponsible: values.emailResponsible,
        emailManager: values.emailManager
      },
      servicesList: [{
        cluster: generalFormBody.cluster,
        total: total,
        materialsList: [{
          amount: summaryData.materiales,
          name: 'Materiales total'
        }],
        servicesList: [{
          amount: summaryData.servicios,
          name: 'Servicios total'
        }],
        movementsList: [{
          amount: summaryData.movimientos,
          name: 'Movimientos total'
        }],
        
      }],
      totalClusterMaterialServiceList: total
    };
    updateGeneralNextBody(body.supervisor);
    try {
      await GeneradoresServices.getInstance().patchGeneralForm(idForm, body).then((data) => {
        updateGeneralNextBody(data.respuesta.general.supervisor);
        updateGeneralBody(data.respuesta.general);
        setShowGenericLoader(false);
        toast.success("Información guardada con éxito.");
      });
    } catch (error) {
      setShowGenericLoader(false);
      toast.error("Ha ocurrido un error, verifique la información.");
      console.log(error);
    }
  };

  const formik = useFormik<GeneralNextFormInfoReqBody>({
    initialValues: {
      name: generalNextBody.name,
      lastName: generalNextBody.lastName,
      motherLastName: generalNextBody.motherLastName,
      phone: generalNextBody.phone,
      email: generalNextBody.email,
      listRegions: generalNextBody.listRegions,
      listResponsible: generalNextBody.listResponsible,
      phoneResponsible: generalNextBody.phoneResponsible,
      emailResponsible: generalNextBody.emailResponsible,
      emailManager: generalNextBody.emailManager
    },
    onSubmit: handleSubmit,
    validationSchema: Yup.object({
      name: Yup.string().required("Campo requerido"),
      lastName: Yup.string().required("Campo requerido"),
      motherLastName: Yup.string().required("Campo requerido"),
      phone: Yup.string().required("Campo requerido").nullable(),
      email: Yup.string()
      .email("Debe ser un correo válido")
      .required("Campo requerido"),
      listRegions: Yup.array(Yup.string()).required("Campo requerido"),
      listResponsible: Yup.array(Yup.string()).required("Campo requerido"),
      phoneResponsible: Yup.string().required("Campo requerido").nullable(),
      emailResponsible:  Yup.string()
      .email("Debe ser un correo válido")
      .required("Campo requerido"),
      emailManager:  Yup.string()
      .email("Debe ser un correo válido")
      .required("Campo requerido")
    }),
  });

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item md={12}>
          <div className={classes.subContainer}>
            <h3>Supervisor Responsable</h3>
            <p>Proveedor</p>
            <div className={classes.inputTripleRow}>
              <TextField
                size="small"
                error={formik.errors.name}
                onChange={formik.handleChange}
                touched={formik.touched.name}
                value={formik.values.name}
                placeholder="Nombre del supervisor"
                label="Nombre del supervisor"
                name="name"
              />

              <TextField
                size="small"
                error={formik.errors.lastName}
                onChange={formik.handleChange}
                touched={formik.touched.lastName}
                value={formik.values.lastName}
                placeholder="Apellido Paterno *"
                label="Apellido Paterno"
                name="lastName"
              />

              <TextField
              size="small"
              error={formik.errors.motherLastName}
              onChange={formik.handleChange}
              touched={formik.touched.motherLastName}
              value={formik.values.motherLastName}
              placeholder="Apellido Materno"
              label="Apellido Materno"
              name="motherLastName"
              />
            </div>

            <div className={classes.inputDoubleRow}>
              <TextField
                size="small"
                error={formik.errors.phone}
                onChange={formik.handleChange}
                touched={formik.touched.phone}
                value={formik.values.phone}
                placeholder="Teléfono *"
                label="Télefono"
                name="phone"
              />

              <TextField
                size="small"
                error={formik.errors.email}
                onChange={formik.handleChange}
                touched={formik.touched.email}
                value={formik.values.email}
                placeholder="Correo *"
                label="Correo"
                name="email"
              />
            </div>
            <p>Totalplay</p>
            <div className={classes.inputDoubleRow}>
            <FormControl variant="outlined" fullWidth margin="dense" error={!!formik.touched.listRegions && !!formik.errors.listRegions}>
              <Typography variant="body2">{"Región"}</Typography>
              <Select
                value={formik.values.listRegions}
                onChange={(e) => {
                  setRegionSelected(e.target.value);
                  formik.setFieldValue("listResponsible", [""]);
                  formik.setFieldValue("emailResponsible", "");
                  formik.setFieldValue("listRegions", [`${e.target.value}`]);
                }}
              >
                <MenuItem value="" disabled>
                  <em>Regiones *</em>
                </MenuItem>
                {regions.map((region) => (
                  <MenuItem key={region} value={region}>
                    {region}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.touched.listRegions && formik.errors.listRegions}</FormHelperText>
            </FormControl>
            </div>
            <FormControl variant="outlined" fullWidth margin="dense" error={!!formik.touched.listResponsible && !!formik.errors.listResponsible}>
            <Typography variant="body2">{"Responsable"}</Typography>
              <Select
                value={formik.values.listResponsible}
                onChange={(e) => {          
                  regionsTree.map((responsable) => {
                    if (responsable.name === e.target.value && responsable.region === regionSelected) {
                      formik.setFieldValue("emailManager", responsable.emailManager);
                      formik.setFieldValue("emailResponsible", responsable.email);
                      formik.setFieldValue("phoneResponsible", responsable.phone);
                    }
                  });
                  formik.setFieldValue("listResponsible", [`${e.target.value}`]);
                }}
              >
                <MenuItem value="" disabled>
                  <em>Responsables *</em>
                </MenuItem>
                {formik.values.listResponsible[0] !== '' ? (
                  <MenuItem value={formik.values.listResponsible[0]}>
                    <em>{formik.values.listResponsible[0]}</em>
                  </MenuItem>
                ) : null}
                {regionSelected !== '' ? regionsTree.map((responsable) => (
                  responsable.region === regionSelected ? (
                    <MenuItem key={responsable.name} value={responsable.name}>
                      {`${responsable.name}`}
                    </MenuItem>
                  ) : null
                )) : null}
              </Select>
              <FormHelperText>{!!formik.touched.listResponsible && formik.errors.listResponsible}</FormHelperText>
            </FormControl>

            <div className={classes.inputDoubleRow}>
              <TextField
                size="small"
                error={formik.errors.phoneResponsible}
                onChange={formik.handleChange}
                touched={formik.touched.phoneResponsible}
                value={formik.values.phoneResponsible}
                placeholder="Teléfono *"
                name="phoneResponsible"
                label="Télefono del responsable"
              />

              <TextField
                size="small"
                error={formik.errors.emailResponsible}
                onChange={formik.handleChange}
                touched={formik.touched.emailResponsible}
                value={formik.values.emailResponsible}
                placeholder="Correo *"
                name="emailResponsible"
                label="Correo del responsable"
              />
               <TextField
                size="small"
                error={formik.errors.emailManager}
                onChange={formik.handleChange}
                touched={formik.touched.emailManager}
                value={formik.values.emailManager}
                placeholder="Correo *"
                name="emailManager"
                label="Correo del Gerente"
              />
              
            </div>

          </div>

          <Button 
            variant="contained" 
            style={{backgroundColor: 'rgba(118, 120, 237, 1)', color: 'rgba(255, 255, 255, 1)'}}
            onClick={() => {
              handleOpenAlert();
            }}
          >
            Cátalogo de servicios          
          </Button>

          <Grid style={{width: '100%', marginTop: '15px', marginBottom: '15px'}}>
            <TableContainer className={classes.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell  className={classes.headerCells}>Cluster</TableCell>
                    <TableCell  className={classes.headerCells}>Concepto</TableCell>
                    <TableCell  style={{borderBottom: '2px solid rgba(0, 0, 0, 1)', textAlign: 'center'}}>Importe $ Mxn</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell />
                    <TableCell className={classes.tableCells}>Servicio:</TableCell>
                    <TableCell className={classes.tableCells}>        
                      {formatCurrency(summaryData?.servicios)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.clusterCells}>{generalFormBody.cluster}</TableCell>
                    <TableCell className={classes.tableCells}>Materiales:</TableCell>
                    <TableCell className={classes.tableCells}>
                      {formatCurrency(summaryData?.materiales)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell className={classes.tableCells}>Movimientos:</TableCell>
                    <TableCell className={classes.tableCells}>
                      {formatCurrency(summaryData?.movimientos)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell className={classes.totalCells}>Total:</TableCell>
                      <TableCell className={classes.totalCells}>
                        {/*formatCurrency(summaryData?.servicios + summaryData?.materiales + summaryData?.movimientos)*/}
                       { formattedTotalSum }
                      </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid container item xs={12} justifyContent="center">
            <div className="text-center">
              <Button className="mt-2 ml-2" onClick={() => goToPreviousForm('FormGeneral')} variant="contained" color="primary" disabled={loading}>
                Anterior
              </Button>
              <Button type="submit" className="mt-2 ml-2" variant="contained" color="primary" disabled={loading}>
                Guardar
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop style={{ zIndex: 2000 }} open={loading} onClick={() => {}}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Modal
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-modal-title"
        aria-describedby="alert-modal-description"
      >
        <div className={classes.modalStyle}>
          <header className={classes.header}>
            <IconButton
              aria-label="close"
              onClick={handleCloseAlert}
              className={classes.closeButton}
            >
              X
            </IconButton>
          </header>
          <Box id="alert-modal-description" className={classes.content}>
            <TableJson sheets={json} /> 
          </Box>
        </div>
      </Modal>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  formField: {
    border: `1px solid #2347AD`,
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  subtitle: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  subContainer: {
    marginBottom: theme.spacing(2),
  },
  personSelect: {
    padding: theme.spacing(1),
  },
  inputDoubleRow: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "1rem",
  },
  inputTripleRow: {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gap: "1rem",
  },
  modalStyle: {
    overflowY: 'scroll',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    boxShadow: theme.shadows[5],
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    border: '2px solid rgba(35, 71, 173, 1)',
    boxShadow: theme.shadows[5],
    backgroundColor: 'rgba(35, 71, 173, 1)',
  },
  closeButton: {
    color: 'rgba(255, 255, 255, 1)',
  },
  content: {
    marginTop: theme.spacing(10),
  },
  tableContainer: {
    color: 'rgba(255, 255, 255, 1)',
    border: '2px solid rgba(0, 0, 0, 1)',
    borderRadius: '8px'
  },
  headerCells: {
    borderBottom: '2px solid rgba(0, 0, 0, 1)', 
    borderRight: '2px solid rgba(0, 0, 0, 1)',  
    textAlign: 'center',                                           
    fontWeight: 'bold',                         
  },
  tableCells: {
    borderBottom: '2px solid rgba(0, 0, 0, 1)',
    borderLeft: '2px solid rgba(0, 0, 0, 1)',
    textAlign: 'center'
  },
  clusterCells: {
    borderTop: '2px solid rgba(0, 0, 0, 0)', 
    borderBottom: '2px solid rgba(0, 0, 0, 0)',
    borderRight: '2px solid rgba(0, 0, 0, 1)',
    textAlign: 'center'
  },
  totalCells: {
    borderLeft: '2px solid rgba(0, 0, 0, 1)',
    textAlign: 'center'
  }
}));

export default FormGeneralNext;
